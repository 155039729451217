import Logo from '@/shared/components/logo/logo.component'
import UserHelper from '@/shared/helpers/user.helper'
import { User } from '@/shared/interfaces/user.interface'
import { MenuItem } from '@/shared/layouts/default/default.layout'
import onCountNotificationsChange from '@/shared/observables/count-notifications.observable'
import NotificationService from '@/shared/services/notification.service'
import { HTMLAttributes, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { SubscriptionLike } from 'rxjs'
import './header-default.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  menu?: MenuItem[]
  user: User
}

export default ({ className = '', menu = [], user, ...rest }: Props) => {
  const [openMenu, setOpenMenu] = useState(false)
  const onCountNotificationsChangeSubscription = useRef<SubscriptionLike>()
  const notificationService = useMemo(() => new NotificationService(), [])
  const menuButton = useRef<any>(null)
  const userHelper = useMemo(() => new UserHelper(), [])
  const [countNotifications, setCountNotifications] = useState<number>(0)
  const { t } = useTranslation('defaultLayout')
  const navigate = useNavigate()
  const onClickItem = (onClickFn: any) => {
    if (onClickFn && typeof onClickFn === 'function') {
      onClickFn()
    }
  }

  const handleInviteRedirect = () => {
    navigate('/invites')
  }

  useEffect(() => {
    notificationService.getCount().then((c) => {
      return setCountNotifications(c?.unread || 0)
    })
  }, [notificationService, navigate])

  useEffect(() => {
    onCountNotificationsChangeSubscription.current = onCountNotificationsChange.subscribe((c) => {
      return setCountNotifications(c)
    })

    return () => {
      onCountNotificationsChangeSubscription.current?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        menuButton.current &&
        !menuButton.current?.contains(event.target)
      ) {
        setTimeout(() => setOpenMenu(false), 300)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setOpenMenu, menuButton])

  

  return <header className={`header-mobile ${className}`} {...rest}>
    <Link to="/" className="brand-logo" style={{ marginRight: 'auto', marginLeft: '0' }}>
      <Logo width='115px' className="text-left main-logo" id="main-logo" />
    </Link>
    <div className='actions'>
      <span className='wrapper-invite'>
        <Link
          className={`invite ${window.location.pathname?.includes('invites') && !openMenu ? 'active' : ''}`}
          id='invite-menu-button'
          to='/invites'
          title={t('invite') as string}
        >
          <i className="fa-solid fa-paper-plane-top"></i>
        </Link>
      </span>
      <span className='wrapper-notificaitons'>
        <Link
          className={`notifications ${window.location.pathname?.includes('notifications') && !openMenu ? 'active' : ''}`}
          id='notification-menu-button'
          to='/notifications'
        >
          <i className="fa-solid fa-bell"></i>
          {countNotifications > 0 ? <span className='badge'></span> : null}
        </Link>
      </span>
      <span className='wrapper-menu'>
        <button
          className={`menu-button ${openMenu ? 'active' : ''}`}
          onClick={() => setOpenMenu(!openMenu)}
          ref={menuButton}
          id='menu-mobile-button'
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <nav className={`menu-mobile scrollbar ${className} ${openMenu ? 'open' : ''}`} {...rest}>
          {
            menu.map((item, key) => {
              const icon = item.icon ? <i className={`menu-icon fa-solid ${item.icon}`} /> : null
                if (item.href) {
                  return (
                    <span key={key}>
                      <a href={item.href} target={item.target} id={item.id}>
                        {icon}
                        <span className="menu-label">{t(`menu.${item.label}`)}</span>
                        <i className="fa-solid fa-chevron-right icon-arrow"></i>
                      </a>
                    </span>
                  )
                }
                if (item.path) {
                  return (
                    <span key={key}>
                      <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to={item.path?.replace(':username', user?.username)} id={item.id}>
                        {icon}
                        <span className="menu-label">{t(`menu.${item.label}`)}</span>
                        <i className="fa-solid fa-chevron-right icon-arrow"></i>
                      </NavLink>
                    </span>
                  )
                }
                if (item.onClick) {
                  return (
                    <span key={key}>
                      <button
                        id={item.id}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          onClickItem(item.onClick)
                        }}
                      >
                        {icon}
                        <span className="menu-label">{t(`menu.${item.label}`)}</span>
                        <i className="fa-solid fa-chevron-right icon-arrow"></i>
                      </button>
                    </span>
                  )
                }
            })
          }
          <span className="logout">
            <button type="button" onClick={() => userHelper.logout(navigate)}>
              <i className="fa-solid fa-sign-out-alt" />
              <span className="menu-label">{t('exit')}</span>
              <i className="fa-solid fa-chevron-right icon-arrow"></i>
            </button>
          </span>
        </nav>
      </span>
    </div>
  </header>
}