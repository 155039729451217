import ErrorLayout from '@/modules/error/layouts/error/error.layout'
import ToggleTheme from '@/shared/components/toggle-theme/toggle-theme.component'
import localForageHelper from '@helpers/localforage.helper'
import LoginLayout from '@modules/login/layouts/login/login.layout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.scoped.scss'
import './App.scss'
import BlankPage from './modules/others/blank/blank.page'
import LoaderComponent from './shared/components/loader/loader.component'
import DefaultLayout from './shared/layouts/default/default.layout'

const App = () => {
  const { i18n } = useTranslation()
  // TODO: Remove when app is back online
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    localForageHelper.getItem('language').then((lang: any) => {
      if (lang) {
        document.documentElement.setAttribute('lang', lang)
        if (lang !== i18n.language) {
          i18n.changeLanguage(lang)
        }
      }
    })
  }, [i18n])

  useEffect(() => {
    // TODO: Remove when app is back online
    window.location.href = 'https://wallbee.io/'
  }, [])

  // TODO: Remove when app is back online
  if (loading) {
    return (
      <div className='loading display-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
        <LoaderComponent size='bigger' />
      </div>
    )
  }

  return (
    <Router>
      <Routes>
        <Route index element={<Navigate to={{ pathname: '/login', search: window.location.search }} />} />
        <Route path='reset-password' element={<Navigate to={{ pathname: '/login/reset-password', search: window.location.search }} />} />
        <Route path='login/*' element={<LoginLayout />} />
        <Route path='error/*' element={<ErrorLayout />} />
        <Route path='generate-invite' element={<BlankPage />} />
        <Route path='*' element={<DefaultLayout />} />
      </Routes>
      <ToggleTheme style={{ display: 'none' }} />
    </Router>
  )
}

export default App
