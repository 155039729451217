import NftFeedPage from '@/modules/nft/pages/feed/feed.page'
import NftPage from '@/modules/nft/pages/nft/nft.page'
import SearchPage from '@/modules/nft/pages/search/search.page'
import DeleteAccountPage from '@/modules/settings/pages/delete-account/delete-account.page'
import GeneralSettingsPage from '@/modules/settings/pages/general-settings/general-settings.page'
import EditProfilePage from '@/modules/user/pages/edit-profile/edit-profile.page'
import InvitesPage from '@/modules/user/pages/invites/invites.page'
import NotificationsPage from '@/modules/user/pages/notifications/notifications.page'
import UserProfilePage from '@/modules/user/pages/user-profile/user-profile.page'
import WalletsPage from '@/modules/user/pages/wallets/wallets.page'
import userObservable from '@/shared/observables/user.observable'
import localForageHelper from '@helpers/localforage.helper'
import { User } from '@interfaces/user.interface'
import { USER_KEY } from '@services/main.service'
import { HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import FooterMobileComponent from './components/footer-mobile/footer-mobile.component'
import HeaderMobileComponent from './components/header-default/header-default.component'
import MenuDesktopComponent from './components/menu-desktop/menu-desktop.component'
import './default.layout.scoped.scss'

export interface MenuItem {
  label: string
  path?: string
  href?: string
  icon?: string
  target?: string
  onClick?: () => any
  id?: string
}

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const onUserChangeSubscription = useRef<Subscription>()
  const [user, setUser] = useState<User | null>()
  const navigate = useNavigate()
  const mainMenu: MenuItem[] = [
    {
      label: 'home',
      path: '/feed',
      icon: 'fa-home',
      id: 'home-mobile-menu'
    },
    {
      label: 'search',
      path: '/search',
      icon: 'fa-search',
      id: 'search-mobile-menu'
    },
    {
      label: 'profile',
      path: '/profile/:username',
      icon: 'fa-user',
      id: 'profile-mobile-menu'
    },
    {
      label: 'wallets',
      path: '/wallets',
      icon: 'fa-wallet',
      id: 'main-menu-wallets'
    },
    {
      label: 'invites',
      path: '/invites',
      icon: 'fa-paper-plane-top',
      id: 'main-menu-invites'
    },
    {
      label: 'settings',
      path: '/settings',
      icon: 'fa-cog',
      id: 'main-menu-settings'
    }
  ]

  useEffect(() => {
    localForageHelper.getItem(USER_KEY).then((userObject: any) => {
      if (!userObject) {
        if (!window.location.pathname?.includes('login')) {
          localForageHelper.setItem('follow_link', `${window.location.pathname}${window.location.search}`)
        }
        return navigate('/login')
      }
      if (userObject) {
        userObservable.next(userObject?.userInfo)
      }
    })
  }, [navigate])

  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  return <div className={`default-layout ${className}`} {...rest}>
    <HeaderMobileComponent user={user as User} menu={mainMenu} />
    <div className='middle'>
      <MenuDesktopComponent user={user as User} menu={mainMenu} />
      <main>
        <Routes>
          <Route index element={<Navigate to={{ pathname: '/feed' }} />} />
          <Route path='feed' element={<NftFeedPage />} />
          <Route path='nft/:id' element={<NftPage />} />
          <Route path='settings' element={<GeneralSettingsPage />} />
          <Route path='delete' element={<DeleteAccountPage />} />
          <Route path='invites' element={<InvitesPage />} />
          <Route path='search/*' element={<SearchPage />} />
          <Route path='wallets' element={<WalletsPage />} />
          <Route path='invites/*' element={<InvitesPage />} />
          <Route path='profile-edit' element={<EditProfilePage />} />
          <Route path='notifications' element={<NotificationsPage />} />
          <Route path='profile/:username' element={<UserProfilePage />} />
          <Route path="*" element={<Navigate to={{ pathname: '/error/not-found' }} />} />
        </Routes>
      </main>
    </div>
    <FooterMobileComponent user={user as User} />
  </div>
}