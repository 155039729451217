import { Nft } from '../interfaces/nft.interface'
import MainService from './main.service'

export default class NftService extends MainService {
  constructor() {
    super()
  }
  async getNftSearch(searchQuery = ' ', page = 1, perPage = 30): Promise<any> {
    searchQuery = searchQuery.trim()
    if (!searchQuery) {
      // generate random search with one letter
      searchQuery = String.fromCharCode(Math.floor(Math.random() * 26) + 97)
    }
    const headers = await this.getHeaders()
    return await this.http.post(`${this.URL}/nft/search?page=${page}&perPage=${perPage}`, { searchQuery }, { headers }).then((r) => r.data)
  }

  async putHidden(payload: any) {
    if (!payload) {
      throw new Error('payload is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.put(`${this.URL}/nft/hidden`, payload, { headers }).then((r) => r.data)
  }

  
  async getNftById(id: any): Promise<Nft> {
    if (!id) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/nft/${id}`, { headers }).then((r) => r.data)
  }

  async getFeed(page: number, perPage: number): Promise<Array<Nft>> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/nft/feed?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async getNftLikesList(nftId: string, page: number, perPage: number): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/nft/${nftId}/likes?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }
}
